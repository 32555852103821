.paymentStatus {
  margin: 20px 0;
  padding: 12px 20px;
  order: -2;
}

@media (min-width: 1000px) {
  .paymentStatus {
    order: 1;
  }
}
